/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react'
import { FooterItem } from '@/components/ui';
import Image from 'next/image';
import webStore from '@/stores/web.store';
import { fetchData } from '@/utils/fetchData';
import style from "./style.module.scss";
import Link from 'next/link';

const Footer = () => {
  const { setLoading, isLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/layout/footer`);
        setDataFilter(data?.data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <section className={`${style.section_footer} `}>
          <div className={`${style.footer_container}  container_long `}>
            <div className={`${style.footer1_container} `}>
              <Link
                href={`/`}
                passHref
              >
                <Image
                  alt="logo"
                  src={dataFilter?.logo_footer.url}
                  width={62}
                  height={16}
                  priority
                />
              </Link>
              <p dangerouslySetInnerHTML={{ __html: dataFilter?.footer?.description }} />
            </div>

            <div className={`${style.footer2_container} `}>

              <div className={`${style.copy_container}`}>
                <p dangerouslySetInnerHTML={{ __html: dataFilter?.copy }} />
                <p dangerouslySetInnerHTML={{ __html: dataFilter?.direccion }} />
              </div>

              <ul className={`${style.ul_container} `}>
                {dataFilter?.rrss && dataFilter?.rrss.length > 0 && dataFilter?.rrss.map((item) => (
                  <li key={item.id}>
                    <FooterItem {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Footer