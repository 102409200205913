/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react'
import Image from 'next/image';
import { NavbarItem, ToggleMenuIcon } from '@/components/ui';
import { fetchData } from '@/utils/fetchData';
import webStore from '@/stores/web.store';
import style from "./style.module.scss";
import Link from 'next/link';

const Navbar = ({ show }) => {
  const { isMenuOpen, setIsToggleMenu, setLoading, isLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/layout/navbar`);
        setDataFilter(data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);


  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <>
          <header className={`${style.section_header_nav} ${show && 'shadow-md '} `}>
            <nav className={`${style.header_nav} ${show ? 'justify-between' : 'justify-center'}  container_long `}>
              <figure className={`${style.header_nav_logo} `}>
                <Link
                  href={`/`}
                  passHref
                >
                  <Image
                    alt="logo"
                    src={dataFilter?.data?.logo?.url}
                    width={62}
                    height={16}
                    priority
                  />
                </Link>
              </figure>

              {show && (
                <div className={`${style.header_nav_content} `}>
                  <div className={`${style.header_nav_options_container}`}>
                    <ul className={`${style.ul_nav_container}`}>
                      {dataFilter.data.enlaces.map((item) => (
                        <li key={item.id}>
                          <NavbarItem {...item} />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    className={`${style.header_nav_button}`}
                    onClick={setIsToggleMenu}
                  >
                    <ToggleMenuIcon isOpen={isMenuOpen} />
                  </button>
                </div>
              )}
            </nav>
          </header>

          <div
            className={`${style.section_header_nav_mobile
              } ${isMenuOpen
                ? 'opacity-100 !top-[56px] sm:!top-[56px] duration-[400ms]'
                : 'opacity-100 duration-[400ms] !-top-[130px]'
              }`}
          >
            <ul className={`${style.ul_nav_mobile_container}`}>
              {dataFilter.data.enlaces.map((item) => (
                <li key={item.id} onClick={setIsToggleMenu}>
                  <NavbarItem {...item} />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
