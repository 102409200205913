import Image from 'next/image'
import Link from 'next/link';
import style from "./style.module.scss";

const FooterItem = (props) => {
  return (
    <div className={`${style.li_options}`}>
      <Link href={props?.enlace}>
        <Image
          alt="icon"
          src={props?.icono?.url}
          width={50}
          height={50}
          sizes="100vw"
          className={`${style.img_options}`}
        />
      </Link>
    </div>
  )
}

export default FooterItem