import { create } from 'zustand'


const webStore = create((set) => ({
  error: null,
  isLoading: true,
  isMenuOpen: false,
  breadcrumbs: [],
  lastNews: [],
  categoriesNews: [],
  categoryNews: [],
  detailNews: [],
  showInitials: 0,
  showLater: 0,
  showColumns: 0,


  /**
   * Metodo para abrir y cerrar el menu
   * @returns
   */
  setIsToggleMenu: () => set(state => ({
    isMenuOpen: !state.isMenuOpen
  })),

  setIsCloseMenu: () => set(state => ({
    isMenuOpen: false
  })),


  /**
   * Metodo para asignar el valor del estado de error
   * @returns
   */
  setError: (newError) => set({ error: newError }),

  /**
   * Metodo para asignar el valor del estado del loading
   * @returns
   */
  setLoading: (newIsLoading) => set({ isLoading: newIsLoading }),

  /**
   * Metodo para asignar el valor de la cantidad de noticias a mostrar
   * @returns
   */
  setShowInitials: (newShowInitials) => set({ showInitials: newShowInitials }),

  /**
   * Metodo para asignar el valor de la cantidad de noticias a mostrar
   * @returns
   */
  setShowLater: (newShowLater) => set({ showLater: newShowLater }),


  /**
   * Metodo para asignar el valor de la cantidad de noticias a mostrar
   * @returns
   */
  setShowColumns: (newShowColumns) => set({ showColumns: newShowColumns }),



  /**
   * Metodo para asignar los valores del contenido de la cada pagina
   * @returns
   */
  setBreadcrumbs: (newData) => set({ breadcrumbs: newData }),
  setLastNews: (newData) => set({ lastNews: newData }),
  setCategoriesNews: (newData) => set({ categoriesNews: newData }),
  setCategoryNews: (newData) => set({ categoryNews: newData }),
  setDetailNews: (newData) => set({ detailNews: newData }),

}))


export default webStore;


