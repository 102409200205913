'use client'
import React from "react";
import { Link as LinkScroll } from "react-scroll";
import webStore from '@/stores/web.store';
import style from "./style.module.scss";

const NavbarItem = (props) => {
  const { setIsToggleMenu } = webStore()

  return (
    <LinkScroll
      href="#"
      onClick={setIsToggleMenu}
      activeClass={``}
      to={props.enlace.replaceAll("#", "")}
      spy={true}
      smooth={true}
      offset={-90}
      duration={600}
      className={`${style.li_nav_options} `}
    >
      {props.titulo}
    </LinkScroll>
  )
}

export default NavbarItem