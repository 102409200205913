/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect } from "react";
import { usePathname, redirect } from "next/navigation";
import Link from 'next/link';
import useScreenResolution from "@/hooks/useScreenResolution";
import { fetchData } from "@/utils/fetchData";
import webStore from "@/stores/web.store";
import style from "./style.module.scss";

const Breadcrumbs = () => {
  const pathname = usePathname();
  const pathParts = pathname.split('/').filter(part => part !== '');
  const { setLoading, breadcrumbs, setBreadcrumbs } = webStore();
  const { deviceType } = useScreenResolution();



  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/blog/breadcrumbs`);
        setBreadcrumbs(data?.data || [])
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, []);


  const finalBreadcrumbs = [
    { label: 'xana', url: '/' },
    ...pathParts.map((part, index) => {
      const url = '/' + pathParts.slice(0, index + 1).join('/');
      const label = (breadcrumbs[index]?.nombre === part || part.charAt(0).toUpperCase() + part.slice(1)).split('-').join(' ');
      return { label, url };
    })
  ];


  function capitalizeFirstWord(text) {
    const words = text.split(' ');
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ');
  }

  const textConvertSplit = (text) => {
    const dataConvert = text.split('-').map(word => word.charAt(0) + word.slice(1)).join(' ')
    return capitalizeFirstWord(dataConvert)
  }

  const existsInArray = (value) => {
    return breadcrumbs.find(item => textConvertSplit(item.slug) === textConvertSplit(value)) !== undefined;
  }


  if (breadcrumbs.length > 0 && pathParts.length > 1 && finalBreadcrumbs[2] && !existsInArray(finalBreadcrumbs[2].label)) {
    redirect('/blog');
  }

  const truncatedText = finalBreadcrumbs[3]?.label.split(' ').slice(0, deviceType === 'mobile' ? 2 : 4).join(' ') + '...';


  return (
    <section>
      <nav className={`${style.section_breadcrumb} `} aria-label="Breadcrumb">
        <ol>
          {finalBreadcrumbs.map((breadcrumb, index) => {
            return (
              <li key={index}>
                {index > 0 && <span className={`${style.arrow}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M6 12L10 8L6 4" stroke="#D7D3D0" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>}
                <Link href={breadcrumb.url}>
                  <span className={`${style.link_text} ${index === finalBreadcrumbs.length - 1 ? '!text-xana-txt-breadcrumb-blue font-medium' : ''}`}>
                    {(index === finalBreadcrumbs.length - 1)
                      ? finalBreadcrumbs.length > 3
                        ? truncatedText : breadcrumb.label
                      : breadcrumb.label}
                  </span>
                </Link>
              </li>
            )
          })}
        </ol>
      </nav>
    </section >

  );
};

export default Breadcrumbs;