import Image from 'next/image'
import style from './style.module.scss';

const LoadingUI = () => {
  return (
    <div className={`${style.loading_contanier} `}>
      <figure className={`${style.image_container} !animate-pulse `}>
        <Image
          alt="logo loading"
          src={`${process.env.NEXT_PUBLIC_RUTA_IMG}/logos/xana.png`}
          width={102}
          height={8}
          priority
        />
      </figure>
    </div>
  )
}

export default LoadingUI