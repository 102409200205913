/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

const useScreenResolution = () => {
  const [resolution, setResolution] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    const device = new UAParser(navigator.userAgent);
    setDeviceType(device.getDevice().type);

    const handleResize = () => {
      setWidth(window.innerWidth || document.documentElement.clientWidth);
      setHeight(window.innerHeight || document.documentElement.clientHeight);

      // Optimizar la resolución solo si ha cambiado
      const newResolution = `${width}x${height}`;
      if (newResolution !== resolution) {
        setResolution(newResolution);
      }
    };

    // Usar addEventListener con una función de callback para evitar problemas de memoria
    window.addEventListener('resize', handleResize);

    // Devolver una función de cleanup para eliminar el event listener
    return () => window.removeEventListener('resize', handleResize);
  }, [resolution]);

  return { deviceType, resolution, width, height };
};

export default useScreenResolution;
