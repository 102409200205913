/**
 *
 * @param {*} url url de la API de NextJs
 * @param {*} method Metodo a usar GET|POST|UPDATE|PATCH|DELETE
 * @param {*} options opciones de cabecera
 * @returns
 */
export const fetchData = async (url, method) => {

  const cors = process.env.NEXT_PUBLIC_URL_WEB

  try {

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': `${cors}`,
      }
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al hacer la solicitud:', error);
    throw error;
  } finally {
  }
};