import style from "./style.module.scss";

const SystemColumn = () => {
  return (
    <div className={`${style.section_container_system_columns} min-h-screen container_long`}>
      <div className={`${style.container_12_columns} min-h-screen`}>
        {[...Array(12)].map((_, index) => (
          <div key={index} className={`${style.item_grid} h-min-screen `}>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SystemColumn