'use client'
import { LoadingUI } from '@/components/ui';
import webStore from '@/stores/web.store';

const LayoutPage = ({ children }) => {
  const { isLoading, isMenuOpen, setIsCloseMenu } = webStore()

  return (
    <>
      {isLoading && <LoadingUI />}
      <div
        className='items-center justify-start min-h-screen w-full mx-auto '
        onClick={isMenuOpen ? setIsCloseMenu : () => { }}
      >
        {children}
      </div>
    </>
  )
}
export default LayoutPage