
const Errors = (props) => {
  return (
    <>
      <div>{props.error}</div>
      <div>{props.status}</div>
    </>
  )
}

export default Errors